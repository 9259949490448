import React from 'react'

export default function Services() {
  return (
    <section className='services mb-12 md:mb-36'>
      <div className="container">
<div className="flex items-center justify-between gap-y-11 flex-wrap child:w-1/2 lg:child:w-auto">
<div className="flex items-center flex-col sm:flex-row gap-x-4 gap-y-5 text-center lg:text-right ">
<svg
className='dark:hidden'
      width={66}
      height={73}
      viewBox="0 0 66 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <g clipPath="url(#clip0_862_573)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M55.297 53.843a9.242 9.242 0 006.53-2.7 9.211 9.211 0 000-13.038 9.241 9.241 0 00-6.53-2.7v18.438z"
          fill="#FDBA74"
        />
        <path
          d="M56.764 52.235V37.01a7.77 7.77 0 014.52 2.676 7.747 7.747 0 010 9.876 7.77 7.77 0 01-4.52 2.677v-.004zm.74-43.756a28.815 28.815 0 00-9.368-6.277A28.852 28.852 0 0016.644 8.48a29.175 29.175 0 00-5.15 6.935c-.862 2.351 1.92 2.925 2.595 1.363A25.97 25.97 0 0126.445 5.203 26.026 26.026 0 0143.32 3.69a25.991 25.991 0 0114.222 9.195 25.918 25.918 0 015.523 15.991v8.405a10.67 10.67 0 00-7.766-3.345 1.47 1.47 0 00-1.468 1.466v18.44a1.464 1.464 0 001.468 1.466 10.722 10.722 0 007.505-3.077c2-1.968 3.147-4.642 3.192-7.446V28.877a28.633 28.633 0 00-2.2-11.047 28.671 28.671 0 00-6.291-9.349l-.002-.002zM63.316 53.688a1.418 1.418 0 00-1.39 1.138 1.412 1.412 0 00.847 1.582 1.418 1.418 0 001.72-.52 1.412 1.412 0 00-1.177-2.2zM42.118 67.715l2.084-.442.442 2.08-2.084.442-.442-2.08zM62.52 58.3a1.47 1.47 0 00-2.001.545 21.001 21.001 0 01-5.505 6.245 21.038 21.038 0 01-7.502 3.62l-.739-3.473a1.467 1.467 0 00-1.741-1.13l-4.956 1.052a1.468 1.468 0 00-1.13 1.739L40 71.846a1.464 1.464 0 001.169 1.138c.19.035.384.032.573-.008l4.955-1.052a24.113 24.113 0 009.452-4.022 24.067 24.067 0 006.914-7.59 1.466 1.466 0 00-.543-2.01z"
          fill="#3F3F46"
        />
        <path
          d="M4.985 55.798A4.989 4.989 0 011.46 54.34 4.972 4.972 0 010 50.821V24.73a4.97 4.97 0 013.077-4.597 4.991 4.991 0 011.908-.38h35.903a4.991 4.991 0 014.605 3.073c.25.604.38 1.25.38 1.904v32.799a3.163 3.163 0 01-2.112 2.995 3.177 3.177 0 01-3.53-.997l-3.028-3.725-32.218-.003zm0-33.333a2.27 2.27 0 00-2.098 1.397c-.114.275-.172.57-.172.867v26.094a2.26 2.26 0 002.266 2.262h33.523l3.844 4.724a.453.453 0 00.804-.285V24.729a2.26 2.26 0 00-2.266-2.262l-35.901-.002z"
          fill="#0D9488"
        />
        <path
          d="M31.287 36.364h9.139v2.373l-4.792 11.828h-2.953l4.651-11.523h-6.045v-2.678zm-3.602-.252v2.596H26.17v2.415h-2.813v-2.415h-6.692V36.11l4.874-9.473h2.992l-4.854 9.473h3.68v-3.367h2.81v3.369h1.52zM5.691 41.12v-2.09l4.831-4.99a4.606 4.606 0 001.618-2.84 1.74 1.74 0 00-1.84-1.846 2.567 2.567 0 00-2.365 1.664L5.589 29.64a4.973 4.973 0 014.671-3.001 4.44 4.44 0 013.303 1.221 4.422 4.422 0 011.367 3.241 6.614 6.614 0 01-2.326 4.542l-2.77 2.784h5.318v2.698l-9.461-.004z"
          fill="#3F3F46"
        />
        <path
          d="M34.38 27.139L21.758 50.565h1.223l12.617-23.424-1.218-.002z"
          fill="#EDB56A"
        />
      </g>
      <defs>
        <clipPath id="clip0_862_573">
          <path fill="#fff" d="M0 0H66V73H0z" />
        </clipPath>
      </defs>
    </svg>
    <svg
    className='hidden dark:inline-block'
      width={66}
      height={73}
      viewBox="0 0 66 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    
    >
      <g clipPath="url(#clip0_1_845)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M55.298 53.843a9.241 9.241 0 006.529-2.7 9.211 9.211 0 000-13.038 9.241 9.241 0 00-6.53-2.7v18.438z"
          fill="#FDBA74"
        />
        <path
          d="M56.764 52.235V37.01a7.77 7.77 0 014.52 2.677 7.747 7.747 0 010 9.875 7.77 7.77 0 01-4.52 2.677v-.004zm.74-43.756a28.814 28.814 0 00-9.367-6.276 28.853 28.853 0 00-31.493 6.276 29.172 29.172 0 00-5.15 6.935c-.862 2.351 1.92 2.925 2.595 1.363A25.97 25.97 0 0126.445 5.203 26.027 26.027 0 0143.32 3.692a25.991 25.991 0 0114.222 9.194 25.917 25.917 0 015.523 15.991v8.405a10.67 10.67 0 00-7.766-3.345 1.47 1.47 0 00-1.468 1.467v18.439a1.464 1.464 0 001.468 1.466 10.722 10.722 0 007.505-3.077c2-1.968 3.147-4.642 3.192-7.446V28.877a28.634 28.634 0 00-2.2-11.047 28.672 28.672 0 00-6.291-9.349l-.002-.002zM63.316 53.688a1.418 1.418 0 00-1.39 1.138 1.412 1.412 0 00.847 1.582 1.418 1.418 0 001.72-.52 1.412 1.412 0 00-1.177-2.2zM42.118 67.715l2.084-.442.442 2.08-2.083.442-.443-2.08zM62.521 58.3a1.47 1.47 0 00-2.002.546 21.002 21.002 0 01-5.505 6.244 21.038 21.038 0 01-7.502 3.62l-.739-3.473a1.465 1.465 0 00-1.168-1.137 1.47 1.47 0 00-.573.007l-4.955 1.052a1.469 1.469 0 00-1.13 1.739L40 71.846a1.465 1.465 0 001.742 1.13l4.955-1.052a24.11 24.11 0 009.452-4.022 24.066 24.066 0 006.914-7.59 1.465 1.465 0 00-.542-2.01z"
          fill="#fff"
        />
        <path
          d="M4.985 55.798A4.989 4.989 0 011.46 54.34 4.972 4.972 0 010 50.821V24.73a4.97 4.97 0 013.077-4.597 4.991 4.991 0 011.908-.38h35.903a4.991 4.991 0 014.605 3.073c.25.604.38 1.25.38 1.904v32.799a3.163 3.163 0 01-2.112 2.995 3.177 3.177 0 01-3.53-.997l-3.028-3.725-32.218-.003zm0-33.333a2.27 2.27 0 00-2.098 1.397c-.114.275-.172.57-.172.867v26.094a2.26 2.26 0 002.266 2.262h33.523l3.844 4.724a.453.453 0 00.804-.285V24.729a2.26 2.26 0 00-2.266-2.262l-35.901-.002z"
          fill="#10B981"
        />
        <path
          d="M31.287 36.365h9.139v2.372l-4.792 11.828h-2.953l4.651-11.522h-6.046v-2.678zm-3.602-.253v2.596H26.17v2.415h-2.813v-2.415h-6.692V36.11l4.874-9.473h2.991l-4.853 9.473h3.68v-3.367h2.81v3.369h1.52zm-21.994 5.01v-2.09l4.831-4.991a4.605 4.605 0 001.618-2.84 1.739 1.739 0 00-1.118-1.733 1.748 1.748 0 00-.722-.113 2.567 2.567 0 00-2.366 1.664L5.59 29.64a4.973 4.973 0 014.671-3.001 4.438 4.438 0 013.303 1.221 4.425 4.425 0 011.367 3.241 6.61 6.61 0 01-2.326 4.542l-2.77 2.784h5.318v2.698L5.69 41.12z"
          fill="#fff"
        />
        <path
          d="M34.38 27.139L21.758 50.564h1.223L35.598 27.14l-1.218-.002z"
          fill="#EDB56A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_845">
          <path fill="#fff" d="M0 0H66V73H0z" />
        </clipPath>
      </defs>
    </svg>
    <div className='text-zinc-700 dark:text-white'>
        <h5 className='font-DanaDemiBold text-sm md:text-lg/6 mb-1 md:mb-3.5'>پشتیبانی شبانه روزی</h5>
        <span className='text-xs md:text-sm/6'>7 روز هفته ، 24 ساعته</span>
    </div>
</div>
<div className="flex items-center flex-col sm:flex-row gap-x-4 gap-y-5 text-center lg:text-right ">
<svg
     className='dark:hidden'
     width={109}
      height={73}
      viewBox="0 0 109 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <g clipPath="url(#clip0_862_604)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.51 8.662L92.442 19v16.186l-10.896 3.562V23.362L47.262 12.386 59.51 8.662z"
          fill="#FDBA74"
        />
        <path
          d="M61.077 8.623L94.224 19.26a1.726 1.726 0 011.2 1.643v14.045a1.739 1.739 0 01-1.151 1.642l-3.24 1.15a1.719 1.719 0 01-1.295-.096 1.74 1.74 0 01-.801-2.278c.188-.41.527-.73.947-.892l2.089-.741v-11.56L55.063 10.33a1.725 1.725 0 01-1.192-1.63 1.738 1.738 0 011.16-1.655l12.143-4.176c.348-.12.726-.124 1.077-.012l38.637 12.245a1.736 1.736 0 011.206 1.642v42.249a1.733 1.733 0 01-1.204 1.642l-38.64 12.29c-.34.109-.705.109-1.046 0L29.05 60.642A1.726 1.726 0 0127.852 59v-5.726a1.738 1.738 0 011.05-1.655 1.72 1.72 0 012.284.974c.084.217.122.448.114.68v4.458l36.434 11.721 36.909-11.72V18.017L67.754 6.328l-6.677 2.295z"
          fill="#3F3F46"
        />
        <path
          d="M105.848 15.326a1.72 1.72 0 011.296.135 1.737 1.737 0 01-.256 3.17L68.251 30.904c-.34.108-.704.108-1.043 0l-29.485-9.42a1.724 1.724 0 01-1.245-1.506 1.74 1.74 0 01.948-1.71 1.72 1.72 0 011.342-.085l28.964 9.252 38.116-12.11z"
          fill="#3F3F46"
        />
        <path
          d="M84.947 36.007a1.718 1.718 0 011.265.15 1.739 1.739 0 01-.184 3.136l-4.254 1.41a1.72 1.72 0 01-2.075-.856 1.738 1.738 0 01-.19-.787V26.281l-36.91-11.844a1.726 1.726 0 01-1.185-1.636 1.738 1.738 0 011.167-1.649l14.49-4.818c.425-.117.88-.065 1.268.145a1.74 1.74 0 01.746 2.262c-.187.402-.52.716-.93.878l-9.456 3.149 33.06 10.608a1.726 1.726 0 011.2 1.643v11.647l1.988-.659z"
          fill="#3F3F46"
        />
        <path
          d="M31.292 18.311v8.348a1.736 1.736 0 01-.526 1.187 1.722 1.722 0 01-2.397 0 1.736 1.736 0 01-.526-1.187v-9.61a1.738 1.738 0 011.209-1.653l13.696-4.312a1.718 1.718 0 011.297.136 1.74 1.74 0 01.724 2.314 1.728 1.728 0 01-.986.856l-12.49 3.921z"
          fill="#3F3F46"
        />
        <path
          d="M45.858 30.489c.457 0 .896.182 1.22.507a1.736 1.736 0 010 2.45 1.722 1.722 0 01-1.22.508H14.844a1.72 1.72 0 01-1.648-1.054 1.74 1.74 0 01.39-1.924 1.724 1.724 0 011.258-.485l31.014-.002zM28.61 38.112c.447.015.87.205 1.181.528a1.737 1.737 0 010 2.408c-.31.323-.734.512-1.181.527H1.725a1.717 1.717 0 01-1.258-.485 1.733 1.733 0 01-.39-1.923 1.733 1.733 0 01.97-.94c.216-.084.447-.123.678-.115H28.61zM9.661 33.954a1.73 1.73 0 001.726-1.732 1.73 1.73 0 00-1.726-1.733 1.73 1.73 0 00-1.725 1.733 1.73 1.73 0 001.725 1.732zM14.49 49.199a1.73 1.73 0 001.725-1.733 1.73 1.73 0 00-1.725-1.733 1.73 1.73 0 00-1.725 1.733 1.73 1.73 0 001.725 1.733zM36.89 45.733c.446.015.87.205 1.18.528a1.737 1.737 0 010 2.408c-.31.323-.734.512-1.18.528H19.663a1.717 1.717 0 01-1.258-.486 1.733 1.733 0 01-.39-1.923 1.733 1.733 0 01.97-.94c.216-.084.447-.123.678-.115H36.89zM54.14 0c.457 0 .896.183 1.22.508a1.737 1.737 0 010 2.45 1.721 1.721 0 01-1.22.508H27.254c-.458 0-.897-.183-1.22-.508a1.737 1.737 0 010-2.45A1.722 1.722 0 0127.255 0h26.884zM27.23 8.315c.458 0 .896.183 1.22.507a1.737 1.737 0 010 2.451 1.722 1.722 0 01-1.22.508H14.146a1.72 1.72 0 01-1.647-1.055 1.74 1.74 0 01.39-1.923 1.724 1.724 0 011.258-.486l13.083-.002z"
          fill="#0D9488"
        />
        <path
          d="M69.686 71.11a1.736 1.736 0 01-.526 1.188 1.722 1.722 0 01-2.397 0 1.736 1.736 0 01-.526-1.187v-8.568c0-.46.182-.9.506-1.225a1.722 1.722 0 012.44 0c.323.325.505.766.505 1.225l-.002 8.568zM69.686 55.581a1.738 1.738 0 01-1.05 1.655 1.718 1.718 0 01-1.915-.391 1.734 1.734 0 01-.484-1.264V30.835c0-.46.182-.9.506-1.225a1.722 1.722 0 012.44 0c.323.325.505.766.505 1.225l-.002 24.746z"
          fill="#3F3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_862_604">
          <path fill="#fff" d="M0 0H108.092V73H0z" />
        </clipPath>
      </defs>
    </svg>
    <svg
    className='hidden dark:inline-block'
      width={109}
      height={73}
      viewBox="0 0 109 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    
    >
      <g clipPath="url(#clip0_1_876)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.51 8.662l32.933 10.337v16.187l-10.896 3.562V23.362L47.262 12.386 59.51 8.662z"
          fill="#FDBA74"
        />
        <path
          d="M61.078 8.623L94.224 19.26a1.726 1.726 0 011.2 1.643v14.045a1.738 1.738 0 01-1.151 1.642l-3.24 1.15a1.719 1.719 0 01-1.295-.096 1.74 1.74 0 01-.801-2.278c.188-.41.528-.73.947-.892l2.089-.742V22.174l-36.91-11.845a1.725 1.725 0 01-1.192-1.63 1.738 1.738 0 011.16-1.655l12.143-4.176c.348-.12.726-.124 1.077-.012L106.888 15.1a1.73 1.73 0 011.206 1.642v42.249a1.733 1.733 0 01-1.204 1.642l-38.639 12.29c-.34.109-.706.109-1.047 0L29.05 60.642A1.726 1.726 0 0127.852 59v-5.726a1.738 1.738 0 011.05-1.655 1.72 1.72 0 012.284.974c.084.217.122.448.114.68v4.458l36.434 11.721 36.91-11.72V18.017l-36.89-11.69-6.676 2.295z"
          fill="#fff"
        />
        <path
          d="M105.848 15.326a1.719 1.719 0 011.296.135c.398.213.697.574.833 1.006a1.73 1.73 0 01-1.089 2.163L68.251 30.905c-.34.108-.703.108-1.043 0l-29.484-9.42a1.724 1.724 0 01-1.048-.846 1.738 1.738 0 01.75-2.37 1.72 1.72 0 011.342-.085l28.964 9.252 38.116-12.11z"
          fill="#fff"
        />
        <path
          d="M84.947 36.007a1.719 1.719 0 011.265.15c.388.21.68.56.819.98.138.42.111.877-.075 1.278a1.73 1.73 0 01-.928.877l-4.254 1.41a1.719 1.719 0 01-2.075-.855 1.74 1.74 0 01-.19-.787V26.282l-36.91-11.845a1.727 1.727 0 01-1.185-1.635 1.738 1.738 0 011.167-1.65l14.49-4.818c.426-.116.88-.064 1.269.145a1.74 1.74 0 01.746 2.262c-.188.402-.521.716-.932.878l-9.455 3.15 33.06 10.608c.348.111.65.33.866.625.216.295.333.651.335 1.017v11.647l1.987-.659z"
          fill="#fff"
        />
        <path
          d="M31.292 18.311v8.348a1.736 1.736 0 01-.525 1.187 1.721 1.721 0 01-2.398 0 1.736 1.736 0 01-.526-1.187v-9.61a1.738 1.738 0 011.209-1.653l13.697-4.311a1.719 1.719 0 011.297.135 1.74 1.74 0 01.724 2.314 1.727 1.727 0 01-.987.856l-12.49 3.921z"
          fill="#fff"
        />
        <path
          d="M45.858 30.488c.457 0 .896.183 1.22.508a1.737 1.737 0 010 2.45 1.722 1.722 0 01-1.22.508H14.844a1.72 1.72 0 01-1.648-1.055 1.74 1.74 0 01.39-1.923 1.723 1.723 0 011.258-.485l31.014-.003zM28.61 38.112c.447.015.87.204 1.181.527a1.736 1.736 0 010 2.408c-.31.324-.734.513-1.181.528H1.725A1.72 1.72 0 01.078 40.52a1.74 1.74 0 01.97-2.294 1.72 1.72 0 01.677-.114H28.61zM9.661 33.954a1.73 1.73 0 001.726-1.733A1.73 1.73 0 009.66 30.49a1.73 1.73 0 00-1.725 1.732 1.73 1.73 0 001.725 1.733zM14.49 49.198a1.73 1.73 0 001.725-1.732 1.73 1.73 0 00-1.725-1.733 1.73 1.73 0 00-1.725 1.733 1.73 1.73 0 001.725 1.732zM36.89 45.733c.447.015.87.205 1.18.528a1.737 1.737 0 010 2.408c-.31.323-.733.512-1.18.527H19.663a1.717 1.717 0 01-1.258-.485 1.733 1.733 0 01-.39-1.923 1.733 1.733 0 01.97-.94c.216-.084.447-.123.678-.115H36.89zM54.14 0c.457 0 .896.183 1.22.508a1.737 1.737 0 010 2.45 1.722 1.722 0 01-1.22.508H27.254c-.458 0-.897-.183-1.22-.508a1.737 1.737 0 010-2.45A1.721 1.721 0 0127.255 0h26.884zM27.23 8.315c.458 0 .896.183 1.22.508a1.737 1.737 0 010 2.45 1.722 1.722 0 01-1.22.508H14.147a1.72 1.72 0 01-1.648-1.055 1.739 1.739 0 01.97-2.295c.216-.083.446-.122.678-.114l13.083-.002z"
          fill="#10B981"
        />
        <path
          d="M69.686 71.111a1.736 1.736 0 01-.526 1.187 1.721 1.721 0 01-2.397 0 1.736 1.736 0 01-.526-1.187v-8.567c0-.46.182-.9.506-1.226a1.722 1.722 0 012.44 0c.323.325.505.766.505 1.226l-.002 8.567zM69.686 55.581a1.742 1.742 0 01-.483 1.264 1.726 1.726 0 01-1.241.529 1.72 1.72 0 01-1.61-1.111 1.738 1.738 0 01-.115-.681V30.834c0-.46.182-.9.506-1.225a1.721 1.721 0 012.44 0c.323.325.505.766.505 1.226l-.002 24.745z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_876">
          <path fill="#fff" d="M0 0H108.092V73H0z" />
        </clipPath>
      </defs>
    </svg>
    <div className='text-zinc-700 dark:text-white'>
        <h5 className='font-DanaDemiBold text-sm md:text-lg/6 mb-1 md:mb-3.5'>امکان تحویل اکسپرس</h5>
        <span className='text-xs md:text-sm/6'>ارسال بسته با سرعت باد</span>
    </div>
</div>
<div className="flex items-center flex-col sm:flex-row gap-x-4 gap-y-5 text-center lg:text-right ">
<svg
className='dark:hidden'
      width={52}
      height={73}
      viewBox="0 0 52 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <path
        d="M29.05 8.5v-7h-7.5v7l-4.5 9.5-.5 12h8V18l4.5-9.5z"
        fill="#0D9488"
      />
      <path
        d="M50.2 16.165L46.234 8.23V1.217C46.233.545 45.69 0 45.017 0H6.083c-.672 0-1.216.545-1.216 1.217V8.23L.899 16.165A8.551 8.551 0 000 19.974v51.81C0 72.454.545 73 1.217 73h48.666c.672 0 1.217-.545 1.217-1.217V19.974a8.552 8.552 0 00-.9-3.81zM30.418 2.433H43.8V7.3H30.417V2.433zM23.01 9.015c.007-.014.017-.053.028-.08.043-.117.068-.24.074-.365 0-.017.01-.03.01-.047v-6.09h4.866l-.006 5.797-3.967 7.935a8.552 8.552 0 00-.9 3.809V29.2H18.25v-9.226a6.127 6.127 0 01.642-2.722l4.103-8.191c.007-.015.008-.03.016-.046zM7.3 2.433h13.383V7.3H7.3V2.433zm31.633 17.541v50.593h-36.5V19.974a6.127 6.127 0 01.643-2.722l3.76-7.519H19.93l-3.215 6.432a8.552 8.552 0 00-.9 3.809v10.443c0 .672.545 1.216 1.217 1.216h7.3c.672 0 1.217-.544 1.217-1.216V19.974a6.127 6.127 0 01.642-2.722l3.76-7.519h13.096l-3.215 6.432a8.552 8.552 0 00-.9 3.809zm9.734 50.593h-7.3V19.974a6.127 6.127 0 01.642-2.722l3.008-6.015 3.007 6.015c.421.846.641 1.777.643 2.722v50.593z"
        fill="#3F3F46"
      />
      <path
        d="M30.05 47.5l-.5-4-2-3h-4.5l-7 3-4.5 6.5.5 7 4.5 2 5-1 6-5 2.5-5.5z"
        fill="#FDBA74"
      />
      <path
        d="M22.265 39.566a15.292 15.292 0 00-7.61 4.295 15.29 15.29 0 00-4.289 7.604 7.504 7.504 0 001.714 7.016 6.882 6.882 0 004.998 1.923c.68 0 1.358-.069 2.024-.203a15.292 15.292 0 007.609-4.295A15.29 15.29 0 0031 48.302a7.504 7.504 0 00-1.715-7.017 7.497 7.497 0 00-7.021-1.72zm-9.514 12.386a12.873 12.873 0 013.63-6.366 12.873 12.873 0 016.37-3.635 7.885 7.885 0 011.531-.159c.98-.026 1.944.25 2.762.79a8.749 8.749 0 01-1.731 1.046 12.288 12.288 0 00-5.719 5.719 8.546 8.546 0 01-1.911 2.697 8.575 8.575 0 01-2.699 1.913c-.75.347-1.46.776-2.115 1.28a6.354 6.354 0 01-.118-3.285zm15.865-4.137a12.873 12.873 0 01-3.63 6.366 12.874 12.874 0 01-6.371 3.635 5.57 5.57 0 01-4.3-.626 8.788 8.788 0 011.74-1.054 11.006 11.006 0 003.342-2.374 10.979 10.979 0 002.381-3.342 9.846 9.846 0 014.609-4.609 11.223 11.223 0 002.11-1.281 6.353 6.353 0 01.119 3.285z"
        fill="#3F3F46"
      />
    </svg>
    <svg className='hidden dark:inline-block' width="52" height="73" viewBox="0 0 52 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.05 8.5V1.5H21.55V8.5L17.05 18L16.55 30H24.55V18L29.05 8.5Z" fill="#10B981"/>
<path d="M50.2009 16.1646L46.2333 8.22953V1.21667C46.2333 0.544762 45.6886 0 45.0167 0H6.08333C5.41143 0 4.86667 0.544762 4.86667 1.21667V8.22953L0.899117 16.1646C0.308882 17.3478 0.00121667 18.6518 0 19.974V71.7833C0 72.4552 0.544763 73 1.21667 73H49.8833C50.5552 73 51.1 72.4552 51.1 71.7833V19.974C51.0988 18.6518 50.7911 17.3478 50.2009 16.1646ZM30.4167 2.43333H43.8V7.3H30.4167V2.43333ZM23.0108 9.0155C23.0181 9.0009 23.0279 8.96197 23.0388 8.9352C23.0821 8.8181 23.1071 8.69491 23.113 8.5702C23.113 8.55317 23.1228 8.53978 23.1228 8.52275V2.43333H27.9894L27.9833 8.22953L24.0158 16.1646C23.4256 17.3478 23.1179 18.6518 23.1167 19.974V29.2H18.25V19.974C18.2515 19.0294 18.4713 18.0979 18.8924 17.2523L22.995 9.06052C23.0023 9.04592 23.0035 9.0301 23.0108 9.0155ZM7.3 2.43333H20.6833V7.3H7.3V2.43333ZM38.9333 19.974V70.5667H2.43333V19.974C2.43485 19.0294 2.65461 18.0979 3.07573 17.2523L6.83523 9.73333H19.9314L16.7158 16.1646C16.1256 17.3478 15.8179 18.6518 15.8167 19.974V30.4167C15.8167 31.0886 16.3614 31.6333 17.0333 31.6333H24.3333C25.0052 31.6333 25.55 31.0886 25.55 30.4167V19.974C25.5515 19.0294 25.7713 18.0979 26.1924 17.2523L29.9519 9.73333H43.0481L39.8325 16.1646C39.2422 17.3478 38.9346 18.6518 38.9333 19.974ZM48.6667 70.5667H41.3667V19.974C41.3682 19.0294 41.588 18.0979 42.0091 17.2523L45.0167 11.2371L48.0243 17.2523C48.4454 18.0979 48.6651 19.0294 48.6667 19.974V70.5667Z" fill="white"/>
<path d="M30.05 47.5L29.55 43.5L27.55 40.5H23.05L16.05 43.5L11.55 50L12.05 57L16.55 59L21.55 58L27.55 53L30.05 47.5Z" fill="#FDBA74"/>
<path d="M22.265 39.5659C19.3649 40.227 16.7206 41.7195 14.6559 43.8607C12.5173 45.9246 11.0266 48.5671 10.366 51.4649C9.70029 53.9429 10.3468 56.5895 12.0802 58.4814C13.4184 59.7706 15.2211 60.464 17.0783 60.4037C17.7578 60.4031 18.4357 60.3352 19.1016 60.2006C22.0017 59.5395 24.646 58.0469 26.7107 55.9057C28.8493 53.8418 30.34 51.1994 31.0006 48.3016C31.6663 45.8235 31.0198 43.177 29.2863 41.285C27.3949 39.547 24.7454 38.8982 22.265 39.5659ZM12.7506 51.9516C13.3185 49.5228 14.5799 47.3113 16.3812 45.586C18.1072 43.7821 20.3206 42.519 22.7516 41.9506C23.2555 41.8481 23.768 41.7951 24.2822 41.7924C25.2621 41.7658 26.2263 42.0415 27.044 42.582C26.5093 42.9966 25.9283 43.3476 25.3127 43.6283C22.816 44.8347 20.8007 46.8499 19.5944 49.3467C19.1389 50.3617 18.4896 51.2778 17.683 52.044C16.916 52.8507 15.9996 53.5002 14.9844 53.9566C14.2344 54.3043 13.5247 54.7335 12.8686 55.2366C12.5418 54.1709 12.5011 53.0379 12.7506 51.9516ZM28.616 47.8149C28.0481 50.2437 26.7867 52.4551 24.9854 54.1805C23.2594 55.9844 21.046 57.2474 18.615 57.8159C17.1562 58.1979 15.6048 57.9724 14.3153 57.1905C14.8524 56.7728 15.4363 56.4187 16.0551 56.1357C17.308 55.5608 18.4417 54.7555 19.3973 53.762C20.394 52.8075 21.2017 51.6736 21.7783 50.4198C22.7468 48.4048 24.3721 46.7795 26.387 45.811C27.1356 45.4629 27.8435 45.0333 28.4979 44.5299C28.8248 45.5955 28.8655 46.7286 28.616 47.8149Z" fill="white"/>
</svg>
    <div className='text-zinc-700 dark:text-white'>
        <h5 className='font-DanaDemiBold text-sm md:text-lg/6 mb-1 md:mb-3.5'>رست تخصصی</h5>
        <span className='text-xs md:text-sm/6'>تازه برشته شده و با کیفیت</span>
    </div>
</div>
<div className="flex items-center flex-col sm:flex-row gap-x-4 gap-y-5 text-center lg:text-right ">
<svg className='dark:hidden' width="76" height="73" viewBox="0 0 76 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9999 35.5L10.4999 33V64.5L11.4999 68L16.4999 72H52.0002L55.0002 70.5L58.0002 67V34.5L45.0002 31.5L35.0002 33L20.0002 35.5H18.9999Z" fill="#FDBA74"/>
<path d="M11.4999 10V3.5L13.9999 1H51.0002L56.0002 2V10H11.4999Z" fill="#0D9488"/>
<path d="M69.0001 8.57485H57.8V4.37483C57.8 2.0547 55.9201 0.174805 53.5999 0.174805H14.8003C12.4808 0.174805 10.6002 2.0547 10.6002 4.37483V8.57485H2.20018C1.70662 8.57485 1.24929 8.83531 0.997046 9.25982C0.744798 9.68433 0.735228 10.21 0.971069 10.6441L7.32716 22.3254C8.55422 24.5854 9.19817 27.1154 9.20022 29.6864V65.9752C9.20501 69.8389 12.3366 72.9705 16.2003 72.9752H52.1999C56.0643 72.9705 59.1952 69.8389 59.2 65.9752V16.9749H66.2001C66.9732 16.9749 67.6001 17.6018 67.6001 18.3749V43.5751C67.6001 45.8945 69.4807 47.7751 71.8002 47.7751C74.1203 47.7751 76.0002 45.8945 76.0002 43.5751V15.5749C75.9954 11.7105 72.8645 8.57896 69.0001 8.57485ZM13.4002 4.37483C13.4002 3.60168 14.0271 2.97482 14.8003 2.97482L53.5999 2.97482C54.3731 2.97482 55 3.60168 55 4.37483V8.57485H13.4002V4.37483ZM4.55654 11.3749H56.4V32.0147C49.5189 30.4473 50 30.5 44.2373 31.3947L32.3913 33.3614C25.6161 34.4921 18.6824 34.2549 12.0002 32.6635V29.6864C11.9982 26.6478 11.2367 23.6571 9.78538 20.9869L4.55654 11.3749ZM52.1999 70.1752H16.2003C13.8808 70.1752 12.0002 68.2946 12.0002 65.9752V35.525C15.8967 36.4103 19.8794 36.8621 23.875 36.8717C26.8828 36.8703 29.8852 36.6215 32.852 36.1266L44.696 34.1585C50 33.3614 49.6829 33.2678 56.4 34.8811V65.9752C56.4 68.2946 54.5201 70.1752 52.1999 70.1752ZM73.2002 43.5751C73.2002 44.3482 72.5733 44.9751 71.8002 44.9751C71.027 44.9751 70.4001 44.3482 70.4001 43.5751V18.3749C70.4001 16.0548 68.5202 14.1749 66.2001 14.1749H59.2V11.3749H69.0001C71.3203 11.3749 73.2002 13.2548 73.2002 15.5749V43.5751Z" fill="#3F3F46"/>
<path d="M52.1999 56.1748C51.4268 56.1748 50.7999 56.8017 50.7999 57.5748V63.1748C50.7999 63.948 50.173 64.5749 49.3999 64.5749H43.7998C43.0267 64.5749 42.3998 65.2017 42.3998 65.9749C42.3998 66.748 43.0267 67.3749 43.7998 67.3749H49.3999C51.72 67.3749 53.5999 65.4943 53.5999 63.1748V57.5748C53.5999 56.8017 52.973 56.1748 52.1999 56.1748Z" fill="#3F3F46"/>
</svg>
<svg className='hidden dark:inline-block' width="76" height="73" viewBox="0 0 76 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 35.5L10.5 33V64.5L11.5 68L16.5 72H52.0002L55.0002 70.5L58.0002 67V34.5L45.0002 31.5L35.0002 33L20.0002 35.5H19Z" fill="#FDBA74"/>
<path d="M11.5 10V3.5L14 1H51.0002L56.0002 2V10H11.5Z" fill="#10B981"/>
<path d="M69.0001 8.57485H57.8V4.37483C57.8 2.0547 55.9201 0.174805 53.5999 0.174805H14.8003C12.4808 0.174805 10.6002 2.0547 10.6002 4.37483V8.57485H2.20018C1.70662 8.57485 1.24929 8.83531 0.997046 9.25982C0.744798 9.68433 0.735228 10.21 0.971069 10.6441L7.32716 22.3254C8.55422 24.5854 9.19817 27.1154 9.20022 29.6864V65.9752C9.20501 69.8389 12.3366 72.9705 16.2003 72.9752H52.1999C56.0643 72.9705 59.1952 69.8389 59.2 65.9752V16.9749H66.2001C66.9732 16.9749 67.6001 17.6018 67.6001 18.3749V43.5751C67.6001 45.8945 69.4807 47.7751 71.8002 47.7751C74.1203 47.7751 76.0002 45.8945 76.0002 43.5751V15.5749C75.9954 11.7105 72.8645 8.57896 69.0001 8.57485ZM13.4002 4.37483C13.4002 3.60168 14.0271 2.97482 14.8003 2.97482L53.5999 2.97482C54.3731 2.97482 55 3.60168 55 4.37483V8.57485H13.4002V4.37483ZM4.55654 11.3749H56.4V32.0147C49.5189 30.4473 50 30.5 44.2373 31.3947L32.3913 33.3614C25.6161 34.4921 18.6824 34.2549 12.0002 32.6635V29.6864C11.9982 26.6478 11.2367 23.6571 9.78538 20.9869L4.55654 11.3749ZM52.1999 70.1752H16.2003C13.8808 70.1752 12.0002 68.2946 12.0002 65.9752V35.525C15.8967 36.4103 19.8794 36.8621 23.875 36.8717C26.8828 36.8703 29.8852 36.6215 32.852 36.1266L44.696 34.1585C50 33.3614 49.6829 33.2678 56.4 34.8811V65.9752C56.4 68.2946 54.5201 70.1752 52.1999 70.1752ZM73.2002 43.5751C73.2002 44.3482 72.5733 44.9751 71.8002 44.9751C71.027 44.9751 70.4001 44.3482 70.4001 43.5751V18.3749C70.4001 16.0548 68.5202 14.1749 66.2001 14.1749H59.2V11.3749H69.0001C71.3203 11.3749 73.2002 13.2548 73.2002 15.5749V43.5751Z" fill="white"/>
<path d="M52.2 56.1748C51.4268 56.1748 50.8 56.8017 50.8 57.5748V63.1748C50.8 63.948 50.1731 64.5749 49.3999 64.5749H43.7999C43.0268 64.5749 42.3999 65.2017 42.3999 65.9749C42.3999 66.748 43.0268 67.3749 43.7999 67.3749H49.3999C51.7201 67.3749 53.6 65.4943 53.6 63.1748V57.5748C53.6 56.8017 52.9731 56.1748 52.2 56.1748Z" fill="white"/>
</svg>

    <div className='text-zinc-700 dark:text-white'>
        <h5 className='font-DanaDemiBold text-sm md:text-lg/6 mb-1 md:mb-3.5'> اکسسوری قهوه</h5>
        <span className='text-xs md:text-sm/6'>وسایل و ادوات دم آوری</span>
    </div>
</div>
</div>
      </div>
    </section>
  )
}
